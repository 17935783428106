import icons from '../images/icons.svg';
import travolta from '../images/404.gif';
import React, { useState, useContext, useEffect, useRef } from 'react';
import { Context as PlayingContext } from '../context/PlayingContext';
import secondsConvert from "../helper/secondsConvert";
import AudioPlayer, { RHAP_UI } from 'react-h5-audio-player';
import getParam from '../helper/getParam';
import ColorThief from "colorthief";

const Player = () => {
	const [touchStart, setTouchStart] = useState(null);
	const [touchEnd, setTouchEnd] = useState(null);
	const [currentTime, setCurrentTime] = useState(null);
	const [running, setRunning] = useState(false);
	const { state, playingIndex, playingAction, loading, paused } = useContext(PlayingContext);
	const player = useRef(null);
	const colorThief = new ColorThief();

	useEffect(() => {
		if (state.loading) setCurrentTime(secondsConvert(0));
		var isPlaying = player.current.audio.current.currentTime > 0 && !player.current.audio.current.paused && !player.current.audio.current.ended && player.current.audio.current.readyState > player.current.audio.current.HAVE_CURRENT_DATA;
		if (state.playing && player.current.audio.current.paused && !state.paused && !state.loading) {
			player.current.audio.current.play();
			return;
		} else if (state.playing && !player.current.audio.current.paused && state.paused && !state.loading && isPlaying) {
			player.current.audio.current.pause();
			return;
		}
		var imgs = document.querySelectorAll('.player-artwork-container img');
		imgs.forEach(img => {
			var src = img.currentSrc || false;
			if (src && !state.loading) {
				if (img.onload) {
					img.style.color = 'rgb(' + colorThief.getColor(img) + ')';
				} else {
					img.addEventListener('load', function() {
						img.style.color = 'rgb(' + colorThief.getColor(img) + ')';
					});
				}
			}
		});
		// eslint-disable-next-line
	}, [state.paused, state.playing, state.loading]);

	const resetImg = () => {
		var imgs = document.querySelectorAll('.player-artwork-container img');
		imgs.forEach(img => {
			img.removeAttribute('style');
		});
	}

	const playerState = (param) => {
		if (document.body.classList.contains('animate')) return;
		if (param === 'up') {
			if (!document.body.classList.contains('player-mini')) {
				document.body.classList.add('player-mini');
			} else if (document.body.classList.contains('player-mini') && !document.body.classList.contains('player-extended')) {
				document.body.classList.add('player-extended', 'animate');
				setTimeout(function() {
					document.body.classList.remove('animate');
				}, 900);
			}
		}
		if (param === 'down') {
			if (document.body.classList.contains('player-mini') && document.body.classList.contains('player-extended')) {
				document.body.classList.remove('player-extended');
				document.body.classList.add('animate');
				setTimeout(function() {
					document.body.classList.remove('animate');
				}, 900);
			} else if (document.body.classList.contains('player-mini')) {
				document.body.classList.remove('player-mini');
			}
		}
		if (param === 'right') {
			if (state.loading) return;
			if (document.body.classList.contains('player-mini')) {
				handleClickNext();
				resetImg();
				document.body.classList.add('swipe-right', 'animate');
				setTimeout(function() {
					document.body.classList.remove('swipe-right', 'animate');
				}, 400);
			}
		}
		if (param === 'left') {
			if (state.loading) return;
			if (document.body.classList.contains('player-mini')) {
				handleClickPrev();
				resetImg();
				document.body.classList.add('swipe-left', 'animate');
				setTimeout(function() {
					document.body.classList.remove('swipe-left', 'animate');
				}, 400);
			}
		}
	}

	const minSwipeDistance = 50;

	const onTouchStart = (e) => {
		setTouchEnd(null);
		setTouchStart(e.targetTouches[0]);
	}

	const onTouchMove = (e) => {
		setTouchEnd(e.targetTouches[0]);
	}

	const bar = document.querySelector('.rhap_progress-bar');
	const indicator = document.querySelector('.rhap_progress-indicator');

	const onTouchEnd = (e) => {
		if (e.target === bar || e.target === indicator) return;
		if (!touchStart || !touchEnd) return;
		var distance = 0;
		if (Math.abs(touchStart.clientX - touchEnd.clientX) > Math.abs(touchStart.clientY - touchEnd.clientY)) {
			distance = touchStart.clientX - touchEnd.clientX;
			const isRightSwipe = distance > minSwipeDistance;
			const isLeftSwipe = distance < -minSwipeDistance;
			if (isRightSwipe || isLeftSwipe) console.log('swipe', isRightSwipe ? 'right' : 'left');
			if (isRightSwipe) {
				playerState('right');
			}
			if (isLeftSwipe) {
				playerState('left');
			}
		} else {
			distance = touchStart.clientY - touchEnd.clientY;
			const isTopSwipe = distance > minSwipeDistance;
			const isBottomSwipe = distance < -minSwipeDistance;
			if (isTopSwipe || isBottomSwipe) console.log('swipe', isTopSwipe ? 'top' : 'bottom');
			if (isTopSwipe) {
				playerState('up');
			}
			if (isBottomSwipe) {
				playerState('down');
			}
		}
	}

	const onHandleClick = (e) => {
		if (document.body.classList.contains('animate')) return;
		if (!document.body.classList.contains('player-mini')) {
			playerState('up');
		} else if (document.body.classList.contains('player-mini') && !document.body.classList.contains('player-extended')) {
			playerState('up');
		} else if (document.body.classList.contains('player-mini') && document.body.classList.contains('player-extended')) {
			playerState('down');
		}
	}

	const onControlsClick = (e) => {
		const playBtn = document.querySelector('.player-controls-play');
		const playerHandle = document.querySelector('.player-controls-handle');
		if (e.target === playBtn || e.target === playerHandle) return;
		if (document.body.classList.contains('player-mini') && !document.body.classList.contains('player-extended')) {
			playerState('up');
		}
	}

	const handleClickNext = () => {
		setRunning(false);
		if (!state.playing) {
			playingAction(true);
		}
		if (running) state.song < state.list.length - 1 ? playingIndex(state.song + 1) : playingIndex(0);

		var urlParams = new URLSearchParams(window.location.search);
		urlParams.set('song', (state.song < state.list.length - 1) ? state.song + 2 : 1);
		var newUrl = window.location.pathname + '?' + urlParams.toString();
		window.history.pushState({ path: newUrl }, '', newUrl);

		window.gtag('event', 'play', {
			'send_to': 'G-LGGJ5539CX',
			'event_category': 'Songs',
			'event_label': state.list[state.song].title
		});
	};

	const handleClickPrev = () => {
		setRunning(false);
		if (!state.playing) {
			playingAction(true);
		}
		if (running) state.song > 0 ? playingIndex(state.song - 1) : playingIndex(state.list.length - 1);

		var urlParams = new URLSearchParams(window.location.search);
		urlParams.set('song', (state.song > 0) ? state.song : state.list.length);
		var newUrl = window.location.pathname + '?' + urlParams.toString();
		window.history.pushState({ path: newUrl }, '', newUrl);

		window.gtag('event', 'play', {
			'send_to': 'G-LGGJ5539CX',
			'event_category': 'Songs',
			'event_label': state.list[state.song].title
		});
	};

	const handleOnListen = (e) => {
		setCurrentTime(secondsConvert(Math.round(e.target.currentTime)));
	}

	const handleOnClickButton = () => {
		if (!state.playing) {
			playingAction(true);
			return;
		}
		state.paused ? paused(false) : paused(true);
	}

	const handlePause = () => {
		if (state.playing && player.current.audio.current.paused && !state.paused) {
			paused(true);
		}
	}

	const artwork = (param) => {
		if (param) {
			if (param === 'prev') return state.song > 0 ? state.list[state.song - 1]?.artwork.m : state.list[state.list.length - 1]?.artwork.m;
			if (param === 'next') return state.song < state.list.length - 1 ? state.list[state.song + 1]?.artwork.m : state.list[0]?.artwork.m;
		} else {
			if (Number(getParam('song', window.location)) === 404) {
				document.body.classList.add('player-mini');
				return travolta;
			}
			return state.list[state.song]?.artwork.m || state.list[state.song + 1]?.artwork.m || '';
		}
	}

	const mediaNotifications = () => {
		if ('mediaSession' in navigator) {
			navigator.mediaSession.metadata = new MediaMetadata({
				title: state.list[state.song]?.title,
				artist: 'Madi',
				album: 'madi.ro',
				artwork: [{
					src: state.list[state.song]?.artwork.m,
					sizes: '512x512',
					type: 'image/jpg'
				}]
			});

			const updatePositionState = () => {
				if (!state.playing || !player.current.audio || state.loading) return;
				if ('setPositionState' in navigator.mediaSession) {
					navigator.mediaSession.setPositionState({
						duration: Math.round(player.current.audio.current.duration),
						playbackRate: player.current.audio.current.playbackRate,
						position: player.current.audio.current.currentTime,
					});
				}
			}

			updatePositionState();

			const actionHandlers = new Map([
				['play', () => {
					player.current.audio.current.play();
					navigator.mediaSession.playbackState = 'playing';
				}],
				['pause', () => {
					player.current.audio.current.pause();
					navigator.mediaSession.playbackState = 'paused';
				}],
				['seekto', (details) => {
					navigator.mediaSession.setPositionState(null);
					player.current.audio.current.currentTime = details.seekTime;
					updatePositionState();
				}],
				['nexttrack', () => {
					setRunning(false);
					if (!state.playing) playingAction(true);
					state.song < state.list.length - 1 ? playingIndex(state.song + 1) : playingIndex(0);
				}],
				['previoustrack', () => {
					setRunning(false);
					if (!state.playing) playingAction(true);
					state.song > 0 ? playingIndex(state.song - 1) : playingIndex(state.list.length - 1);
				}]
			]);

			for (const [action, handler] of actionHandlers) {
				try {
					navigator.mediaSession.setActionHandler(action, handler);
				} catch (error) {
					console.log(`${action} is not supported`);
				}
			}
		}
	}

	return (
		<div className="player-controls" onClick={onControlsClick}>
			<div className="player-controls-handle" onClick={onHandleClick}></div>
			<div className="player-controls-wrapper">
				<div className="player-artwork" onTouchStart={onTouchStart} onTouchMove={onTouchMove} onTouchEnd={onTouchEnd}>
					<div className="player-artwork-container">
						<img src={artwork('prev')} alt="" className="img-prev" />
						<img src={artwork()} alt={state.list[state.song]?.title || ''} className="img-main" />
						<img src={artwork('next')} alt="" className="img-next" />
					</div>
				</div>
				<div className="player-controls-details" onTouchStart={onTouchStart} onTouchMove={onTouchMove} onTouchEnd={onTouchEnd}>
					<div className="player-controls-details-title">
						<h3>{state.list[state.song]?.title || ''}</h3>
						<h4>{currentTime ? currentTime : secondsConvert(state.list[state.song]?.length)}</h4>
					</div>
					<div className="player-controls-details-button">
						<button type="button" className="player-controls-btn player-controls-play" onClick={handleOnClickButton}>
							{(state.paused || !state.playing) && <svg>
								<use xlinkHref={`${icons}#play`} />
							</svg>}
							{(!state.paused && state.playing) && <svg>
								<use xlinkHref={`${icons}#pause`} />
							</svg>}
						</button>
					</div>
				</div>
				<div className="player-controls-audioplayer">
					<h3>{state.list[state.song]?.title || ''}</h3>
					<AudioPlayer
						autoPlayAfterSrcChange={true}
						showSkipControls={true}
						showJumpControls={false}
						src={state.list[state.song]?.source}
						customProgressBarSection={
							[
								RHAP_UI.CURRENT_TIME,
								RHAP_UI.DURATION,
								RHAP_UI.PROGRESS_BAR
							]
						}
						customControlsSection={
							[
								RHAP_UI.MAIN_CONTROLS
							]
						}
						ref={player}
						onClickNext={() => playerState('right')}
						onClickPrevious={() => playerState('left')}
						onListen={handleOnListen}
						onEnded={() => playerState('right')}
						customIcons={{
							play: <svg><use xlinkHref={`${icons}#play`} /></svg>,
							pause: <svg><use xlinkHref={`${icons}#pause`} /></svg>,
							previous: <svg className={(state.list.length < 2 ? 'disabled' : '')}><use xlinkHref={`${icons}#backward-end`} /></svg>,
							next: <svg className={(state.list.length < 2 ? 'disabled' : '')}><use xlinkHref={`${icons}#forward-end`} /></svg>
						}}
						volume={1}
						onPlay={() => {
							playingAction(true);
							paused(false);
						}}
						onPause={() => handlePause()}
						onPlaying={() => { setRunning(true) }}
						onLoadStart={() => { state.playing && loading(true) }}
						onLoadedData={() => {
							state.playing && loading(false)
							mediaNotifications();
						}}
					/>
				</div>
			</div>
		</div >
	);
}

export default Player;
