import anotheronevibesthedusk from '../assets/Madi - Another One Vibes the Dusk.zip';
import myownway from '../assets/Madi - My Own Way.zip';
import tegusta from '../assets/Madi - Te Gusta.zip';
import throughtime from '../assets/Madi - Through Time.zip';
import lovehope from '../assets/Madi - Love & Hope.zip';
import caribbean from '../assets/Madi - Caribbean.zip';
import buddha_bar_ii from '../assets/Madi - Buddha-Bar II.zip';
import la_obsession from '../assets/Madi - LA Obsession.zip';
import rain_in_october from '../assets/Madi - Rain in October.zip';
import sands_of_time from '../assets/Madi - Sands of Time.zip';
import the_king_of_egypt_resurrection from '../assets/Madi - The King of Egypt Resurrection.zip';
import liberty_opera_voice from '../assets/Madi - Liberty (opera voice).zip';
import obses_me from '../assets/Madi - Obses Me.zip';
import buddha_bar from '../assets/Madi - Buddha-Bar.zip';

const songs = [
	{
		"title": "Another One Vibes the Dusk",
		"length": 157,
		"artwork": {
			"s": "images/another-one-vibes-the-dusk-72x72.jpg",
			"m": "images/another-one-vibes-the-dusk-512x512.jpg"
		},
		"source": anotheronevibesthedusk,
		"year": "2024"
	},
	{
		"title": "My Own Way",
		"length": 163,
		"artwork": {
			"s": "images/my-own-way-72x72.jpg",
			"m": "images/my-own-way-512x512.jpg"
		},
		"source": myownway,
		"year": "2024"
	},
	{
		"title": "Te Gusta",
		"length": 175,
		"artwork": {
			"s": "images/te-gusta-72x72.jpg",
			"m": "images/te-gusta-512x512.jpg"
		},
		"source": tegusta,
		"year": "2024"
	},
	{
		"title": "Through Time",
		"length": 235,
		"artwork": {
			"s": "images/through-time-72x72.jpg",
			"m": "images/through-time-512x512.jpg"
		},
		"source": throughtime,
		"year": "2024"
	},
	{
		"title": "Love & Hope",
		"length": 218,
		"artwork": {
			"s": "images/love-hope-72x72.jpg",
			"m": "images/love-hope-512x512.jpg"
		},
		"source": lovehope,
		"year": "2024"
	},
	{
		"title": "Caribbean",
		"length": 238,
		"artwork": {
			"s": "images/caribbean-72x72.jpg",
			"m": "images/caribbean-512x512.jpg"
		},
		"source": caribbean,
		"year": "2024"
	},
	{
		"title": "Buddha-Bar II",
		"length": 237,
		"artwork": {
			"s": "images/buddha-bar-2-72x72.jpg",
			"m": "images/buddha-bar-2-512x512.jpg"
		},
		"source": buddha_bar_ii,
		"year": "2017"
	},
	{
		"title": "LA Obsession",
		"length": 224,
		"artwork": {
			"s": "images/la-obsession-72x72.jpg",
			"m": "images/la-obsession-512x512.jpg"
		},
		"source": la_obsession,
		"year": "2013"
	},
	{
		"title": "Rain in October",
		"length": 240,
		"artwork": {
			"s": "images/rain-in-october-72x72.jpg",
			"m": "images/rain-in-october-512x512.jpg"
		},
		"source": rain_in_october,
		"year": "2012"
	},
	{
		"title": "Sands of Time",
		"length": 259,
		"artwork": {
			"s": "images/sands-of-time-72x72.jpg",
			"m": "images/sands-of-time-512x512.jpg"
		},
		"source": sands_of_time,
		"year": "2010"
	},
	{
		"title": "The King of Egypt Resurrection",
		"length": 281,
		"artwork": {
			"s": "images/the-king-of-egypt-resurrection-72x72.jpg",
			"m": "images/the-king-of-egypt-resurrection-512x512.jpg"
		},
		"source": the_king_of_egypt_resurrection,
		"year": "2009"
	},
	{
		"title": "Liberty (opera voice)",
		"length": 260,
		"artwork": {
			"s": "images/liberty-opera-voice-72x72.jpg",
			"m": "images/liberty-opera-voice-512x512.jpg"
		},
		"source": liberty_opera_voice,
		"year": "2009"
	},
	{
		"title": "Obses Me",
		"length": 247,
		"artwork": {
			"s": "images/obses-me-72x72.jpg",
			"m": "images/obses-me-512x512.jpg"
		},
		"source": obses_me,
		"year": "2009"
	},
	{
		"title": "Buddha-Bar",
		"length": 264,
		"artwork": {
			"s": "images/buddha-bar-72x72.jpg",
			"m": "images/buddha-bar-512x512.jpg"
		},
		"source": buddha_bar,
		"year": "2007"
	}
]

export default songs;
