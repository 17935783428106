import React from "react";
import icons from '../images/icons.svg';

const PlayerHeader = () => {
	const _body = document.querySelector('body');

	const openAvatar = () => {
		if (_body.classList.contains('player-extended')) return;
		if (_body.classList.contains('avatar-open')) {
			_body.classList.remove('avatar-open');
		} else {
			_body.classList.add('avatar-open');
		}
	}

	return (
		<div className="player-header">
			<div className="player-header-title" title="Madi's Music">
				<svg>
					<use xlinkHref={`${icons}#logo`} />
				</svg>
			</div>
			<div className="player-header-avatar" onClick={() => openAvatar()}>
				<img src="images/avatar.jpg" alt="avatar" />
				<span></span>
			</div>
			<div className="player-header-description">
				<div className="player-header-description-container">
					<h2>Despre mine</h2>
					<p>Mă numesc Alin Dima, născut în București, undeva prin 89'.</p>
					<p>Am început să "compun", deși este mult spus "compun", undeva prin 2002, când am deschis pentru prima dată FruityLoops (actualul FL Studio). Eram fascinat de ce poți face pe un simplu calculator.</p>
					<p>Cu timpul am reușit să strâng peste 200 de piese, dar împart cu voi doar pe cele care, cred eu, merită.</p>
					<p>Melodiile mele apar din când în când, până la urmă, este un hobby.</p>
					<p>Și ca întotdeauna... Enjoy! :)</p>
					<div className="player-header-social">
						<div className="player-header-social-item">
							<a href="https://www.youtube.com/@alindima.c" target="_blank" rel="noreferrer">
								<svg>
									<use xlinkHref={`${icons}#youtube`} />
								</svg>
							</a>
						</div>
						<div className="player-header-social-item">
							<a href="https://www.facebook.com/madismusic" target="_blank" rel="noreferrer">
								<svg>
									<use xlinkHref={`${icons}#facebook`} />
								</svg>
							</a>
						</div>
						<div className="player-header-social-item">
							<a href="mailto:contact@madimusic.ro" target="_blank" rel="noreferrer">
								<svg>
									<use xlinkHref={`${icons}#mail`} />
								</svg>
							</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default PlayerHeader;
