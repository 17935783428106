import createDataContext from './createDataContext';

const playerReducer = (state, action) => {
	switch (action.type) {
		case 'playing':
			return { ...state, playing: action.payload };
		case 'paused':
			return { ...state, paused: action.payload };
		case 'playingIndex':
			return { ...state, song: action.payload };
		case 'playlist':
			return { ...state, list: [...action.payload] };
		case 'loading':
			return { ...state, loading: action.payload };
		default:
			return state;
	}
}

const playingAction = dispatch => (playing) => {
	dispatch({ type: 'playing', payload: playing });
};

const playingIndex = dispatch => (playingSongIndex) => {
	dispatch({ type: 'playingIndex', payload: playingSongIndex });
};

const playlist = dispatch => (playingList) => {
	dispatch({ type: 'playlist', payload: playingList });
};

const loading = dispatch => (loading) => {
	dispatch({ type: 'loading', payload: loading });
};

const paused = dispatch => (paused) => {
	dispatch({ type: 'paused', payload: paused });
};

export const { Context, Provider } = createDataContext(playerReducer, { playingAction, playingIndex, playlist, loading, paused }, { playing: false, song: 0, list: [], loading: false, paused: false });
