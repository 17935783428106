import React from "react";
import ReactDOM from "react-dom/client";
import App from "./app";
import { Provider as PlayerProvider } from './context/PlayingContext';

const el = document.getElementById('app');
const root = ReactDOM.createRoot(el);

root.render(
	<PlayerProvider>
		<App />
	</PlayerProvider>
);
