import '../style/main.css';
import React from "react";
import PlayerHeader from "../components/PlayerHeader";
import PlayerPlaylist from "../components/PlayerPlaylist";
import Player from "../components/Player";

const App = () => {

	return (
		<div id="player">
			<PlayerHeader />
			<PlayerPlaylist />
			<Player />
		</div>
	);

};

export default App;
