
import { useContext, useEffect } from "react";
import PlayerPlaylistItem from "./PlayerPlaylistItem";
import { Context as PlayingContext } from '../context/PlayingContext';
import secondsConvert from "../helper/secondsConvert";
import getParam from '../helper/getParam';

import songs from '../assets/songs';

const PlayerPlaylist = () => {
	const { state, playlist, playingIndex } = useContext(PlayingContext);

	useEffect(() => {
		if (state.list?.length > 0) {
			const svgs = document.querySelectorAll('.equalizer');
			svgs.forEach(svg => {
				(state.paused) ? svg.pauseAnimations() : svg.unpauseAnimations();
			});
		}
	}, [state]);

	useEffect(() => {
		playlist(songs);
		const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
		if (!isMobile) {
			document.body.classList.add('desktop');
		}
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		const param = Number(getParam('song', window.location));
		if (param && state.list[param - 1]) {
			document.body.classList.add('player-mini');
			playingIndex(param - 1);
		} else {
			playingIndex(0);
		}
		// eslint-disable-next-line
	}, [state.list]);

	return (
		<div className="player-content">
			<div className="player-playlist">
				{state.list?.length > 0 && state.list.map(({ title, artwork, length, year }, index) => (
					<PlayerPlaylistItem title={title} length={(length > 0) ? secondsConvert(length) : ''} artwork={artwork.m} key={`player-${index}`} year={year} index={index} />
				))}
			</div>
		</div>
	);
}

export default PlayerPlaylist;
