import React, { useContext, useEffect } from 'react';
import { Context as PlayingContext } from '../context/PlayingContext';

function PlayerPlaylistItem({ title, length, artwork, year, index }) {
	const { state, playingAction, playingIndex, paused } = useContext(PlayingContext);

	useEffect(() => {
		if (state.playing && !document.body.classList.contains('player-mini')) {
			document.body.classList.add('player-mini');
		}
	}, [state.playing, state.loading]);

	const handleClick = (item) => {
		if (state.loading) return;
		var urlParams = new URLSearchParams(window.location.search);
		urlParams.set('song', item + 1);
		var newUrl = window.location.pathname + '?' + urlParams.toString();
		window.history.pushState({ path: newUrl }, '', newUrl);

		window.gtag('event', 'play', {
			'send_to': 'G-LGGJ5539CX',
			'event_category': 'Songs',
			'event_label': state.list[state.song].title
		});


		if (!state.playing) {
			playingAction(true);
		} else {
			if (state.paused) {
				paused(false);
			} else {
				paused(true);
			}
		}
		playingIndex(item);
	};

	const iconPlaying = () => {
		return (
			<svg className="equalizer" viewBox="0 0 100 100" xmlns="<http://www.w3.org/2000/svg>">
				<g transform="rotate(180 45 50)">
					<rect x="60" y="12.5" width="30" height="40" fill="#fff">
						<animate attributeName="height" calcMode="spline" values="50;75;10;50" times="0;0.33;0.66;1" dur="1s" keySplines="0.5 0 0.5 1;0.5 0 0.5 1;0.5 0 0.5 1" repeatCount="indefinite" begin="-0.125s" />
					</rect>
					<rect x="25" y="12.5" width="30" height="40" fill="#fff">
						<animate attributeName="height" calcMode="spline" values="50;75;10;50" times="0;0.33;0.66;1" dur="1s" keySplines="0.5 0 0.5 1;0.5 0 0.5 1;0.5 0 0.5 1" repeatCount="indefinite" begin="-0.625s" />
					</rect>
					<rect x="-10" y="12.5" width="30" height="40" fill="#fff">
						<animate attributeName="height" calcMode="spline" values="50;75;10;50" times="0;0.33;0.66;1" dur="1s" keySplines="0.5 0 0.5 1;0.5 0 0.5 1;0.5 0 0.5 1" repeatCount="indefinite" begin="-0.75s" />
					</rect>
				</g>
			</svg>
		)
	}

	return (
		<div className=
			{`player-playlist-item${(state.playing && state.song === index) ? ' active' : ''}${state.loading && state.song === index ? ' loading' : ''}`}
			onClick={() => handleClick(index)}>
			<figure>
				{iconPlaying()}
				<img src={artwork} alt={title} className="img" />
			</figure>
			<h3>{title}<span>{year}</span></h3>
			<h4>{length}</h4>
		</div>
	);
}

export default PlayerPlaylistItem;
